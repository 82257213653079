<template>
  <div>
    <h3>Добавить информацию о наличии</h3>
    <!--    <PriceFilter />-->
    <!--    <PricesTable catalog-type="cw" :loading="loading" />-->
  </div>
</template>

<script>
// import PriceFilter from "@/components/prices/PricesFilter"
// import PricesTable from "@/components/prices/PricesTable"

export default {
  name: "PricesInfo",
  // components: {
  //   PriceFilter,
  //   PricesTable,
  // },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    await this.getPrices()
  },
  methods: {
    async getPrices() {
      this.loading = true
      await this.$store.dispatch("prices/fetchPrices", "cw")
      this.loading = false
    },
  },
}
</script>
